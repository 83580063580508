import { FormEvent, useState } from "react"
import { DndContext, closestCenter, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers"
import { useOrgData } from "../../../context/OrgContext"
import Form from "../../commmon/Form"
import FormRow from "../../commmon/FormRow"
import CheckboxField from "../../fields/CheckboxField"
import InputField from "../../fields/InputField"
import DraggableElement from "../../commmon/DraggableElement";
import { useAlert } from "../../../context/AlertContext";
import { apiCall, apiRequest } from "../../../functions";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import TextAreaField from "../../fields/TextAreaField";
import SelectField from "../../fields/SelectField";

import AwardSection from "../awards/AwardSection";

type SeasonFormProps = Readonly<{
    season?: Season
    invalidFields?: GenericObject
    onFormSuccess: (season: Season | null) => void
    onFormCancel: () => void
}>

const SeasonForm = (props: SeasonFormProps) => {
    const { setAlertMessage, setAlertFromApiMessage } = useAlert();
    const { org, awardData, sportTypes, seasonTypes } = useOrgData();
    const [sectionOrder, setSectionOrder] = useState<number[]>(awardData?.awards.map(x => x.id) || []);

    async function onSubmit(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        try {
            const result = await apiRequest<Season>(`/seasons/save`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    seasonData: {
                        id: props.season?.id,
                        name: formData["name"],
                        year: formData["year"],
                        current: formData["isCurrent"],
                        sportId: formData["sportId"],
                        seasonTypeId: formData["seasonTypeId"],
                        confirmationMessage: formData["confirmationMessage"],
                        organizationId: org?.id
                    } as Season
                })
            })

            setAlertFromApiMessage(result);

            if (result.success) {
                props.onFormSuccess(result.data as Season);

                // Now reorder the sections
                await apiCall<AwardSection[]>(`/awards/sections/reorder`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        seasonId: result.data?.id,
                        sectionIds: sectionOrder
                    })
                })
            }
            else {
                console.error(result.error)
            }
        }
        catch (err) {
            console.log(err);
            setAlertMessage((err as Error).message, AlertTypeEnum.ERROR);
            return false;
        }
        finally {
            return true;
        }
    }

    async function onFormDelete(): Promise<boolean> {
        try {
            if (window.confirm("Are you sure you want to delete this season?")) {
                const result = await apiRequest("/seasons/delete", {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        seasonId: props.season?.id
                    })
                })
                
                if (result.success) {
                    setAlertFromApiMessage(result);
                    props.onFormSuccess(null);
                }
                else {
                    console.error(result);
                    setAlertMessage(`There was an error when attempting to delete the season.`, AlertTypeEnum.ERROR);
                }
            }
        }
        catch (err) {
            console.error(err);
            setAlertMessage(`Unable to delete the season from an unknown error.`, AlertTypeEnum.ERROR);
            return false;
        }
        finally {
            return true;
        }
    }

    function onDragEnd(e: DragEndEvent) {
        const { active, over } = e;
        
        if (active.id !== over?.id) {
            setSectionOrder((order) => {
                const oldIndex = order.indexOf(Number(active.id));
                const newIndex = order.indexOf(Number(over?.id));
                return arrayMove(order, oldIndex, newIndex);
            })
        }
    }

    return (
        <Form 
            onSubmitClicked={onSubmit}
            onDeleteClicked={onFormDelete}
            onCancelClicked={props.onFormCancel}
            showDelete={props.season?.id !== undefined}
        >
            <FormRow>
                <InputField type="text" placeholder="Name" name="name" defaultValue={props.season?.name} />
            </FormRow>
            <FormRow className="sm">
                <InputField type="number" placeholder="Year" name="year" defaultValue={props.season?.year} />
            </FormRow>
            <FormRow className="sm">
                <SelectField label="Season" value={props.season?.seasonTypeId} name="seasonTypeId">
                    {
                        seasonTypes.map((s) => <option key={`season-${s.id}`} value={s.id}>{s.name}</option>)
                    }
                </SelectField>
            </FormRow>
            <FormRow className="sm">
                <SelectField label="Sport" value={props.season?.sportId} name="sportId">
                    {
                        sportTypes.filter(x => !x.archived).map(s => <option key={s.id} value={s.id}>{s.name}</option>)
                    }
                </SelectField>
            </FormRow>
            <FormRow className="sm">
                <CheckboxField
                    name="isCurrent"
                    type="switch"
                    label="Active"
                    checked={props.season?.current || false}
                />
            </FormRow>
            <FormRow className="full">
                <TextAreaField 
                        min={0}
                        max={3000}
                        message={props.season?.confirmationMessage}
                        name="confirmationMessage"
                        label="Confirmation Message"
                        subLabel="Users will be told their votes have been recorded. Add in any extra information you wish to tell your users here."
                    />
            </FormRow>
            {
                awardData?.awards ? (
                    <FormRow className="full">
                        <h3>Section Order</h3>
                        <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} modifiers={[restrictToParentElement, restrictToVerticalAxis]}>
                            <SortableContext 
                                items={sectionOrder}
                                strategy={verticalListSortingStrategy}
                            >
                                {
                                    sectionOrder.map(sectionId => {
                                        const section = awardData?.awards.find(x => x.id === Number(sectionId)) as AwardSection;
                                        return section ? (
                                            <DraggableElement key={section.id} id={section.id}>
                                                <AwardSection section={section} isActive={true} />
                                            </DraggableElement>
                                        ) : null
                                    })
                                }
                            </SortableContext>
                        </DndContext>
                    </FormRow>
                ) : null
            }
        </Form>
    )
}

export default SeasonForm;
