import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import QuestionTypeEnum from "../enum/QuestionType";
import SelectableAnswer from "./answertypes/SelectableAnswer";
import PlayerSearchAnswer from "./answertypes/PlayerSearchAnswer";
import PointScaleAnswer from "./answertypes/PointScaleAnswer";
import TextAnswer from "./answertypes/TextAnswer";
import RefereeRating from "./answertypes/RefereeRatingAnswer";
import InfoLabel from "./commmon/InfoLabel";

type AnswersProps = Readonly<{
    question: AwardQuestion
    currentAnswer?: AwardPlayerResponse
    answers: Team[] | TeamPlayers[] | MultipleChoiceAnswer[]
    onDecisionMadeForQuestion: (response: AwardPlayerResponse) => void
    onAnswerHasError: (hasError: boolean, message: string) => void
}>

const Answers = (props: AnswersProps) => {
    const state = useLocation().state as TeamPlayers;
    const [answerValue, setAnswerValue] = useState<string>("");

    useEffect(() => {
        if (props.currentAnswer?.response) {
            let value = "";
            
            if (props.currentAnswer?.responseValue) {
                if ("name" in props.currentAnswer?.responseValue) {
                    value = (props.currentAnswer?.responseValue as Team).name;
                }
                else if ("player" in props.currentAnswer?.responseValue) {
                    value = (props.currentAnswer?.responseValue as TeamPlayers).player.firstName + " " + (props.currentAnswer?.responseValue as TeamPlayers).player.lastName
                }
            }
            else {
                if (props.question.questionTypeId === QuestionTypeEnum.POINTSCALE) {
                    value = `${props.currentAnswer?.response} out of ${props.question.max}`
                }
                else if (props.question.questionTypeId === QuestionTypeEnum.MULTIPLECHOICE) {
                    value = props.question.multipleChoiceAnswers.find(x => x.id === Number(props.currentAnswer?.response))?.value || `INVALID ANSWER`;
                }
                else {
                    value = props.currentAnswer?.response ? props.currentAnswer?.response.toString() : "";
                }
            }

            setAnswerValue(value);
        }
    }, [props.currentAnswer, props.question]);

    function onDecisionMade(response: number | string, hasError?: boolean) {
        if (hasError) {
            props.onAnswerHasError(hasError, "N/A");
        }
        else {
            const answer = {
                ...props.currentAnswer,
                questionId: props.question.id,
                response,
                responseValue: undefined
            } as AwardPlayerResponse

            props.onDecisionMadeForQuestion(answer);
        }
    }

    if (!props.question.section?.locked) {
        let enableFilterBar = [QuestionTypeEnum.ROOKIEONLY, QuestionTypeEnum.DIVISION].includes(props.question.questionTypeId);

        switch (props.question.questionTypeId) {
            case QuestionTypeEnum.ROOKIEONLY:
            case QuestionTypeEnum.DIVISION:
            case QuestionTypeEnum.BESTTEAMPLAYER:
            case QuestionTypeEnum.MULTIPLECHOICE:
            case QuestionTypeEnum.TEAMPLAYERS:
            case QuestionTypeEnum.TEAMPHOTO:
            case QuestionTypeEnum.TEAM:
                return (
                    <SelectableAnswer
                        currentValue={props.currentAnswer}
                        onDecisionMade={onDecisionMade}
                        answers={props.answers} 
                        disablePlayerId={props.question.allowSelfVote ? -1 : state.id}
                        disableTeamId={props.question.allowSelfTeamVote ? -1 : state.teamId}
                        showFilter={enableFilterBar}
                    />
                );
            case QuestionTypeEnum.PLAYER:
                return (
                    <PlayerSearchAnswer
                        currentValue={props.currentAnswer}
                        onDecisionMade={onDecisionMade}
                        disablePlayerId={props.question.allowSelfVote ? -1 : state.id}
                        disableTeamId={props.question.allowSelfTeamVote ? -1 : state.teamId}
                    />
                );
            case QuestionTypeEnum.POINTSCALE:
                return (
                    <PointScaleAnswer
                        question={props.question}
                        currentValue={props.currentAnswer}
                        onDecisionMade={onDecisionMade}
                    />
                );
            case QuestionTypeEnum.TEXT:
                return (
                    <TextAnswer
                        question={props.question}
                        currentValue={props.currentAnswer}
                        onDecisionMade={onDecisionMade}
                    />
                );
            case QuestionTypeEnum.REFEREERATING:
                return (
                    <RefereeRating
                        question={props.question}
                        currentValue={props.currentAnswer}
                        onDecisionMade={onDecisionMade}
                        answers={props.answers as TeamPlayers[]} 
                    />
                );
            case QuestionTypeEnum.SUBMISSION:
                return (
                    <div className="center">
                        <sub>
                            All provided responses have been recorded. Please check the Question Navigation to verify you have voted in all available awards / questions.
                        </sub>
                    </div>
                )
            case QuestionTypeEnum.MESSAGE:
            default:
                return <></>;
        }
    }
    else {
        return (
            <div className="locked-response">
                <InfoLabel type="warning">
                    <sub>
                        Voting has been locked, and no further changes can be made.
                    </sub>
                </InfoLabel>
                <div className="current-vote">
                    {
                        props.currentAnswer?.responseValue ? (
                            <SelectableAnswer
                                answers={[props.currentAnswer?.responseValue]}
                            />
                        ) : (
                            answerValue
                        )
                    }
                </div>
            </div>
        )
    }
}

export default Answers;
