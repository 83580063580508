import { ReactNode } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DragIcon } from "../../svgs/drag_indicator.svg";

import "../../styles/scss/draggable-element.scss";

type DraggableElementProps = Readonly<{
    id: string | number
    children: ReactNode
}>

const DraggableElement = (props: DraggableElementProps) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, index } = useSortable({
        id: props.id
    })
    const elementStyling = {
        transform: CSS.Transform.toString(transform),
        transition,
        index
    };

    return (
        <div className="draggable-element" ref={setNodeRef} style={elementStyling}>
            <div className="text-display">
                {props.children}
            </div>
            <aside {...attributes} {...listeners} role="button" ref={setActivatorNodeRef}>
                <DragIcon className="icon" />
            </aside>
        </div>
    )
}

export default DraggableElement;