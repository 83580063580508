import { useLocation, useNavigate } from "react-router-dom";
import PlayerForm from "./forms/PlayerForm";

import "../../styles/scss/PlayerDetail.scss";

const PlayerDetail = () => {
    const nav = useNavigate();
    const state = useLocation().state;

    return (
        <div id="player-detail">
            <div className="player">
                <PlayerForm player={state.player} onFormCancelled={() => nav(-1)} onFormSuccess={() => nav(-1)}/>
            </div>
        </div>
    )
}

export default PlayerDetail;
