import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import AwardSeason from "./AwardSeason";
import SeasonSelect from "./SeasonSelect";
import Home from "./Home";
import Profile from "./Profile";
import PasswordReset from "./PasswordReset";
import PasswordResetForce from "./PasswordResetForce";
import CreateAccount from "./CreateAccount";
import Integrations from "./Integrations";
import OrgSelection from "./OrgSelection";
import UserManager from "../commmon/UserManager";
import OrgSettings from "./OrgSettings";
import AwardCertificate from "./AwardCertificate";
import PlayerDetailsPage from "./PlayerDetailsPage";
import NotFound404 from "../pages/NotFound404";
import PlayerDetail from "./PlayerDetail";

const OrgMain = function() {
    const { user } = useAuth();
    const nav = useNavigate();

    useEffect(() => {
        if (!user) {
            // Redirect the user back to the root. It will redirect there.
            nav("/");
        }
    }, [user]);

    return (
        user ? (
            <div className="org-page">
                <Routes>
                    <Route path="/" element={<OrgSelection />} />
                    <Route path="user/reset" element={<PasswordReset />} />
                    <Route path="user/create" element={<CreateAccount />} />
                    <Route path="user/resetforce" element={<PasswordResetForce />} />
                    <Route path=":orgId" element={<Home />}>
                        <Route path="integrations" element={<Integrations />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="users" element={<UserManager />} />
                        <Route path="seasons" element={<SeasonSelect />}/>
                        <Route path="seasons/:seasonId" element={<AwardSeason />}/>
                        <Route path="seasons/:seasonId/certificates" element={<AwardCertificate />} />
                        <Route path="settings" element={<OrgSettings />} />
                        <Route path="players" element={<PlayerDetailsPage />} />
                        <Route path="players/:playerId" element={<PlayerDetail />} />
                    </Route>
                    <Route path="*" element={<NotFound404 />} />
                </Routes>
            </div>
        ) : (
            <Navigate to="/" />
        )
    )
}

export default OrgMain;