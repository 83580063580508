import "../../styles/scss/fields/InputField.scss";

export type InputFieldProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { 
    name: string, 
    label?: string 
}

const InputField = (props: InputFieldProps) => {

    return (
        <fieldset className="input-field">
            <label htmlFor={props.name}>
                {props.label ?? props.placeholder}
            </label>
            <input id={props.name} aria-label={props.name} {...props} />
        </fieldset>
    )
}

export default InputField;