import { ChangeEvent, ReactNode, useState } from "react"

type SelectFieldProps = Readonly<{
    name: string,
    value?: any,
    label: string
    children: ReactNode
    required?: boolean
    onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}>

const SelectField = (props: SelectFieldProps) => {
    const required: boolean = props.required ?? false;
    const [value, setValue] = useState<any>(props.value)

    function setSelectedValue(e: ChangeEvent<HTMLSelectElement>) {
        setValue(e.currentTarget.value);

        if (props.onChange) {
            props.onChange(e);
        }
    }

    return (
        <div className="select-field">
            <label>
                {props.label}
            </label>
            <select name={props.name} required={props.required} value={value} onChange={setSelectedValue}>
                <option value=""></option>
                { props.children }
            </select>
        </div>
    )
}

export default SelectField;