import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";

import "../../styles/scss/collapsible.scss";

type CollapsibleProps = Readonly<{
    children: ReactNode,
    title?: string
    footer?: ReactNode
    showNav?: boolean
    className?: string
    onClick?: () => void
}>

const Collapsible = (props: CollapsibleProps) => {
    const [showContent, setShowContent] = useState<boolean>(props.showNav ?? false);

    useEffect(() => {
        if (props.showNav !== undefined) {
            setShowContent(props.showNav);
        }
    }, [props.showNav])

    function onClick() {
        if (props.showNav === undefined)  {
            setShowContent(!showContent)
        }

        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <div 
            className={clsx(
                `collapsible-wrapper`,
                showContent ? "show" : "hide",
                showContent === true ? "display-only" : "",
                props.className
            )}
        >
            <div className="header" onClick={onClick}>
                <h3 className="title">
                    {props.title}
                </h3>
                <div className="flipper icon">
                    <svg viewBox="0 0 15 15">
                        <g>
                            <path d="M 0 10 
                                l 0 -5 
                                l 5 0 
                                l 0 -5 
                                l 5 0
                                l 0 5
                                l 5 0
                                l 0 5
                                l -5 0
                                l 0 5
                                l -5 0
                                l 0 -5
                                Z" 
                            />
                        </g>
                    </svg>
                </div>
            </div>
            <div className={`content`}>
                <div>
                    { props.children }
                </div>
            </div>
            <div className="footer" onClick={onClick}>
                {props.footer}
            </div>
        </div>
    )
}

export default Collapsible;
