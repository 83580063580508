import { ReactNode, useState } from "react"
import clsx from "clsx"
import ProgressBar from "../../commmon/ProgressBar"
import QuestionForm from "../forms/QuestionForm"

import {ReactComponent as EditIcon} from "../../../svgs/pencil.svg";
import Modal from "../../commmon/Modal";

type AwardQuestionProps = Readonly<{
    question: AwardQuestion
    votePercent: number
    isActive: boolean
    isEditable?: boolean
    children: ReactNode
    onQuestionUpdate?: () => void
}>

const AwardQuestion = (props: AwardQuestionProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);

    function onShowForm() {
        setIsEditing(true);
    }

    function onCloseForm() {
        setIsEditing(false);
    }

    function onFormSuccess() {
        setIsEditing(false);

        if (props.onQuestionUpdate) {
            props.onQuestionUpdate();
        }
    }

    return (
        <div id={`question-${props.question.id}`} className={clsx("award-question", props.isActive ? "active" : "inactive")}>
            <header>
                <div className="flex">
                    <ProgressBar percent={props.votePercent} legend={props.question.text} /> 
                    <div>
                        <EditIcon className="icon" onClick={onShowForm} />
                    </div>
                </div>
                <sub>
                    { props.question.description || "No Description" }
                </sub>
            </header>
            <div className="question-content">
                { props.children }
            </div>
            {
                isEditing && (
                    <Modal onModalClose={onCloseForm}>
                        <QuestionForm question={props.question} onFormSuccess={onFormSuccess} onFormCancel={onCloseForm} />
                    </Modal>
                )
            }
        </div>
    )
}

export default AwardQuestion;
