import { FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import { apiCall } from "../../../functions";
import { useAlert } from "../../../context/AlertContext";
import Form from "../../commmon/Form";
import ImageField from "../../fields/ImageField";
import InputField from "../../fields/InputField";

import "../../../styles/scss/forms/PlayerForm.scss";

type PlayerFormProps = Readonly<{
    player?: Player,
    onFormCancelled: () => void
    onFormSuccess: (p: Player) => void
}>

const PlayerForm = (props: PlayerFormProps) => {
    const { orgId, playerId } = useParams();
    const { setAlertMessage } = useAlert();

    async function onPlayerFormSubmitted(e: FormEvent<HTMLFormElement>, formData: GenericObject): Promise<boolean> {
        if (!formData.orgId) {
            setAlertMessage(`Form is misisng field values. Form was not submitted.`, AlertTypeEnum.WARNING);
            return false;
        }

        const { photo, orgId: formOrgId, ...playerData } = formData;
        const form: FormData = new FormData();
        form.append("orgId", formOrgId);
        form.append("photo", photo);
        form.append("player", JSON.stringify(playerData));

        apiCall<Player>(`/players/save`, {
            method: "PUT",
            body: form
        })
        .then((resp) => {
            if (resp.data) {
                setAlertMessage(`Player ${formData.firstName} ${formData.lastName} has been updated.`, AlertTypeEnum.SUCCESS);
                props.onFormSuccess(resp.data);
            }
            else {
                setAlertMessage(`There was an error in saving this player.`, AlertTypeEnum.ERROR);
            }
        })
        .catch((err) => {
            console.error(err)
            setAlertMessage(`There was an error in saving this player.`, AlertTypeEnum.ERROR);
        })
        
        return true;
    }
    
    return (
        <div id="player-form">
            <Form onSubmitClicked={onPlayerFormSubmitted} onCancelClicked={props.onFormCancelled}>
                <div className="player-picture">
                    <ImageField src={`/api/players/image?id=${props.player?.id ?? playerId}`} name="photo" overlayText="Click to change" />
                </div>
                <div className="player-info">
                    <InputField type="hidden" name="id" label="" defaultValue={props.player?.id} disabled={true} />
                    <InputField type="hidden" name="orgId" label="" defaultValue={orgId} disabled={true} />
                    <InputField type="text" name="firstName" label="First Name" defaultValue={props.player?.firstName} />
                    <InputField type="text" name="aka" label="AKA" defaultValue={props.player?.aka} />
                    <InputField type="text" name="lastName" label="Last Name" defaultValue={props.player?.lastName} />
                    <InputField type="text" name="email" label="Email" defaultValue={props.player?.email} />
                </div>
            </Form>
        </div>
    )
}

export default PlayerForm;
