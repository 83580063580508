import { useEffect, useState } from "react";
import { useOrgData } from "../../../context/OrgContext";
import SeasonTeam from "../SeasonTeam";
import { apiCall } from "../../../functions";
import Button from "../../commmon/Button";

type TeamPageProps = Readonly<{
    seasonId: number | undefined
}>

const TeamPage = (props: TeamPageProps) => {
    const { season, teams } = useOrgData();
    const [ nonTeamPlayers, setNonTeamPlayers ] = useState<TeamPlayers[]>([]);
    const [ addNewTeam, setAddNewTeam ] = useState<boolean>(false);

    useEffect(() => {
        apiCall<TeamPlayers[]>(`/teamplayers/freeagents/?seasonId=${props.seasonId}`)
        .then((resp) => setNonTeamPlayers(resp.data ?? []));
    }, [season?.id]);

    function onNewTeamClick() {
        setAddNewTeam(true);
    }

    return (
        <div className="season-teams">
            {
                teams.map(team => {
                    return (
                        <SeasonTeam 
                            key={`team-${team.id}`} 
                            team={team} 
                        />
                    )
                })
            }
            {
                nonTeamPlayers.length > 0 ? (
                    <SeasonTeam 
                        key={"nonteamplayers"}
                        team={{
                            name: "Free Agents",
                            seasonId: props.seasonId,
                            teamPlayers: nonTeamPlayers
                        } as Team}
                    />
                ) : null
            }
            {
                addNewTeam ? (
                    <SeasonTeam 
                        team={{ 
                            teamPlayers: [] as TeamPlayers[]
                        } as Team}
                        onHideNewTeamForm={() => setAddNewTeam(false)}
                    />
                ) : (
                    <Button className="card center" onClick={onNewTeamClick}>
                        Add Team
                    </Button>
                )
            }
        </div>
    )
}

export default TeamPage;
