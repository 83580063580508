//@ts-ignore
import CanvasJSReact from "@canvasjs/react-charts";

import "../../../styles/scss/admin-question-point-scale.scss";

type AwardPointScaleProps = Readonly<{
    responses: Array<Nominee>
}>

const AwardPointScale = (props: AwardPointScaleProps) => {
    const CanvasChart = CanvasJSReact.CanvasJSChart;
    const CanvasJS = CanvasJSReact.CanvasJS;

    CanvasJS.addColorSet("pointBarGraphColors",  [
        "#61BAFB"
    ])

    const canvasChartOptions = {
        animationEnabled: true,
        colorSet: "pointBarGraphColors",
        axisX: {
            title: "Value",
            includeZero: true
        },
        axisY: {
            title: "Votes",
        },
        data: [{
            type: "bar",
            dataPoints: props.responses.sort((a, b) => Number(a.textResponse) - Number(b.textResponse)).map((d) => {
                return { 
                    y: Number(d.votes), 
                    label: d.textResponse 
                }
            })
        }]
    }

    return (
        <div className="point-scale-feedback">
            <CanvasChart 
                options={canvasChartOptions}
                containerProps={{ width: '100%', height: '100%' }}
            />
        </div>
    )
}

export default AwardPointScale;
