import { createRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useOrgData } from "../context/OrgContext";
import { useSiteInfo } from "../context/SiteInfoContext";
import AwardNav from "../navs/AwardNav";
import AdminNav from "../navs/AdminNav";
import OrgNav from "../navs/OrgNav";
import { ReactComponent as ProfileIcon } from "../svgs/user-circle-o.svg";
import { ReactComponent as CloseIcon } from "../svgs/cross.svg";
import useOutsideEvent from "../hooks/useClickOutside";

const Header = function() {
    const menuRef: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
    const { version, db } = useSiteInfo();
    const { org, loadData } = useOrgData();
    const { user, player } = useAuth();
    const [showMenu, setShowMenu] = useState<boolean>(false);
    
    useOutsideEvent(menuRef, onMenuClickedOutside);

    function onMenuOpen(): void {
        setShowMenu(true);
    }

    function onMenuClose(): void {
        loadData(undefined);
        setShowMenu(false);
    }

    function onMenuToggle(): void {
        setShowMenu(!showMenu);
    }

    function onUserLogout(): void {
        onMenuClose();
    }

    function onMenuClickedOutside(): void {
        if (showMenu) {
            setShowMenu(false);
        }
    }

    return (
        <header id="app-header">
            <div className="header-content">
                <div id="site-logo">
                    {
                        org?.logoPath ? (
                            <img src={`/api/organization/${org.id}/photo?${org.updated}`} alt="logo" />
                        ) : null
                    }
                </div>
                <div id="site-title">
                    AWARDS
                </div>
                {
                    !user?.canReset ? (
                        <div>
                            <div id="user-info">
                                {
                                    user || player ? (
                                        <ProfileIcon className="icon" onClick={onMenuToggle}/>
                                    ) : null
                                }
                            </div>
                            <div id="nav-container">
                                <nav id={`nav-main-menu`} className={showMenu ? "show": "hide"} ref={menuRef}>
                                    <div className="icon close-icon" onClick={onMenuToggle}>
                                        <CloseIcon />
                                    </div>
                                    <div>
                                        {`Hello, ${(user || player)?.firstName}`}
                                    </div>
                                    <div className="links">
                                        {
                                            player ? (
                                                <AwardNav onElementClicked={onMenuClose}/>
                                            ) : null
                                        }
                                        {
                                            user ? (
                                                <OrgNav onElementClicked={onMenuClose}/>
                                            ) : null
                                        }
                                        {
                                            user?.siteAdmin ? (
                                                <AdminNav onElementClicked={onMenuClose}/>
                                            ) : null
                                        }
                                        <Link className="link" to="/logout" onClick={onUserLogout}>
                                            Logout
                                        </Link>
                                    </div>
                                    <div className="site-info">
                                        <sub>Version {version}</sub>
                                        {
                                            db ? (
                                                <sub>DB: {db}</sub>
                                            ) : null
                                        }
                                    </div>
                                </nav>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </header>
    )
}

export default Header;