import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiCall, apiRequest } from "../../functions";
import { TeamDivision } from "../../enum/TeamDivision";
import { useAlert } from "../../context/AlertContext";
import Button from "../commmon/Button";
import Card from "../commmon/Card";
import SeasonPlayer from "./SeasonPlayer";
import TeamForm from "./forms/TeamForm";
import { useOrgData } from "../../context/OrgContext";
import AlertTypeEnum from "../../enum/AlertTypeEnum";
import ImageField from "../fields/ImageField";
import Modal from "../commmon/Modal";
import PlayerForm from "./forms/PlayerForm";

import { ReactComponent as EditIcon } from "../../svgs/pencil.svg";
import "../../styles/scss/admin-team.scss";

type SeasonTeamProps = Readonly<{
    team: Team
    onHideNewTeamForm?: () => void
}>

const SeasonTeam = function({ team, ...props }: SeasonTeamProps) {
    const { reloadTeamData } = useOrgData();
    const { seasonId } = useParams();
    const { setAlertFromApiMessage, setAlertMessage } = useAlert();
    const [showPlayerForm, setShowPlayerForm] = useState<boolean>(false);
    const [showPlayers, setShowPlayers] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        if (!team.id && team.teamPlayers.length === 0) { 
            setIsEditing(true);
        }
    }, [])
 
    function onToggleShowPlayers() {
        if (!isEditing) {
            setShowPlayers(!showPlayers);
        }
    }

    function onTeamPhotoChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files && seasonId && team.id) {
            const formData: FormData = new FormData();
            formData.append("photo", e.target.files[0])
            formData.append("seasonId", seasonId?.toString());
            formData.append("teamId", team.id.toString())

            apiRequest<Team>(`/teams/upload/photo`, {
                method: "POST",
                body: formData
            })
            .then((json) => {
                setAlertFromApiMessage(json);
            })
            .catch((err) => {
                console.error(err);
                setAlertMessage(`An unexpected error occured when uploading your photo`, AlertTypeEnum.ERROR);
            })
        }
    }

    function onEditIconClicked(e: MouseEvent<SVGSVGElement>) {
        e.preventDefault();
        e.stopPropagation();

        if (team.id) {
            setIsEditing(true);
            setShowPlayers(false);
        }
    }

    function onFormSuccess() {
        if (props.onHideNewTeamForm) {
            props.onHideNewTeamForm();
        }
        setIsEditing(false);
        reloadTeamData();
    }

    function onFormCancel() {
        if (props.onHideNewTeamForm) {
            props.onHideNewTeamForm();
        }
        setIsEditing(false);
    }

    function onNewPlayerSaved(p: Player) {
        // Save the player to the team
        apiRequest<TeamPlayers>(`/teamplayers/save`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                teamPlayer: {
                    seasonId,
                    teamId: team.id,
                    playerId: p.id,
                    captian: false,
                } as Partial<TeamPlayers>
            })
        })
        .then((resp) => {
            if (resp.success) {
                setAlertMessage(`Player was added to the team`, AlertTypeEnum.SUCCESS);
                setShowPlayerForm(false);
                onFormSuccess();
            }
            else {
                setAlertMessage(`There was an error adding this player to the team.`, AlertTypeEnum.ERROR);
            }
        })
        .catch((err) => {
            setAlertMessage(`There was an error adding this player to the team.`, AlertTypeEnum.ERROR);
            console.log(err);
        })
    }

    return (
        <Card className="season-team display-only" onClick={onToggleShowPlayers}>
            {
                isEditing ? (
                    <TeamForm 
                        seasonId={seasonId}
                        team={team}
                        onFormSuccess={onFormSuccess}
                        onFormCancel={onFormCancel}
                    />
                ) : (
                    <>
                        <Card.Header>
                            <h3>
                                <div className="team-name">
                                    <div className="name">
                                        {team.name} 
                                    </div>
                                    {
                                        team.id && (
                                            <EditIcon className="icon" onClick={onEditIconClicked} />
                                        )
                                    }
                                </div>
                                <div className={`collapsible-menu-button ${showPlayers ? `expanded` : `collapsed`}`} />
                            </h3>
                        </Card.Header>
                        <Card.Content>
                            <div className={`season-team-players ${showPlayers ? "content-show" : "content-hide"}`} onClick={(e) => e.stopPropagation()}>
                                <ImageField 
                                    name="team-photo"
                                    src={team.imagePath ?? ""}
                                    overlayText="Click To Upload Photo"
                                    onImageChanged={onTeamPhotoChange}
                                />
                                <div className="players">
                                    <ul>
                                        <li className="player-header">
                                            <div>Name</div>
                                            <div>Captain</div>
                                        </li>
                                        {
                                            team.teamPlayers.map(x => (
                                                <li key={`player-${x.id}`}>
                                                    <SeasonPlayer 
                                                        teamPlayer={x} 
                                                        teamId={team.id}
                                                        onRefreshTeam={reloadTeamData}
                                                    />
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                {
                                    showPlayerForm ? (
                                        <Modal onModalClose={() => { setShowPlayerForm(false); }}>
                                            <PlayerForm 
                                                onFormCancelled={() => setShowPlayerForm(false)} 
                                                onFormSuccess={onNewPlayerSaved} 
                                            />
                                        </Modal>
                                    ) : (
                                        <Button className={`card center`} onClick={() => setShowPlayerForm(true)}>
                                            Add Player
                                        </Button>
                                    )
                                }
                            </div>
                        </Card.Content>
                    </>
                )
            }
            <Card.Footer>
                <div>
                    {
                        team.division ? (
                            <>{TeamDivision[team.division].toString()} - </>
                        ) : null
                    } {team.teamPlayers.length} players
                </div>
            </Card.Footer>
        </Card>
    )
}

export default SeasonTeam;