import clsx from "clsx"
import { ChangeEvent, useEffect, useState } from "react"
import useDebounce from "../../hooks/useDebounce"
import InputField from "../fields/InputField"

type SelectableAnswerProps = Readonly<{
    currentValue?: AwardPlayerResponse
    disablePlayerId?: number
    disableTeamId?: number
    showFilter?: boolean
    onDecisionMade?: (id: number) => void
    answers: Array<TeamPlayers | Team | MultipleChoiceAnswer>
}>

const SelectableAnswer = (props: SelectableAnswerProps) => {
    const searchDebounce = useDebounce(filterAnswers, 1000);
    const [filterText, setFilterText] = useState<string>("");
    const [filteredPlayers, setFilteredPlayers] = useState<Array<TeamPlayers | Team | MultipleChoiceAnswer>>([]);
    const disablePlayerId: number = props.disablePlayerId ?? 0;
    const disableTeamId: number = props.disableTeamId ?? 0;
    const showFilter: boolean = props.showFilter ?? props.answers.length > 20;

    useEffect(() => {
        setFilteredPlayers(props.answers);
    }, [props.answers])

    function isTeamPlayer(value: any): value is TeamPlayers {
        return "player" in value;
    }

    function isTeam(value: any): value is Team {
        return "name" in value;
    }

    function isMultiChoiceAnswer(value: any): value is MultipleChoiceAnswer {
        return "value" in value;
    }

    function onAnswerSelected(id: number, isDisabled: boolean) {
        if (!isDisabled && props.onDecisionMade) { 
            props.onDecisionMade(id)
        }
    }

    function onFilterChange(e: ChangeEvent<HTMLInputElement>) {
        const searchText = e.currentTarget.value
        setFilterText(searchText);
        searchDebounce(searchText);
    }

    function filterAnswers(args: any[]) {
        const search: string = args[0];

        // Fuzzy search player info
        if (search) {
            const players = props.answers.filter((x) => {
                if (isTeamPlayer(x)) {
                    const fullName = `${x.player.firstName} ${x.player.lastName}`;
                    if (fullName.toLowerCase().includes(search.toLowerCase())) {
                        return x;
                    }
                }
                if (isTeam(x)) {
                    if (x.name.toLowerCase().includes(search.toLowerCase())) {
                        return x;
                    }
                }
                if (isMultiChoiceAnswer(x)) {
                    return x;
                }

                return false;
            })

            console.log(players);
            setFilteredPlayers(players);
        }
        else {
            setFilteredPlayers(props.answers);
        }
    }

    return (
        <>
            {
                showFilter ? (
                    <div className="search-bar">
                        <div className="search-box">
                            <InputField value={filterText} onChange={onFilterChange} name="filter-field" placeholder="Search for players" label=" "/>
                        </div>
                    </div>
                ) : null
            }
        
            <div className="selectable-answers">
                {
                    filteredPlayers.map((a) => {
                        let answerTitle: string = "";
                        let aka: string = "";
                        let subTitle: string = "";
                        let photo: string | undefined;
                        let isDisabled: boolean = false;
                        let className: string = "";

                        if (isTeamPlayer(a as TeamPlayers)) {
                            const teamPlayer: TeamPlayers = a as TeamPlayers;
                            aka = teamPlayer.player.aka
                            answerTitle = `${teamPlayer.player.firstName}${aka ? ` "${aka}" ` : " "}${teamPlayer.player.lastName}`;
                            subTitle = teamPlayer.team.name;
                            photo = teamPlayer.player.photo ? `/api/players/image?id=${teamPlayer.playerId}` : "";
                            className = "teamplayer"

                            //Check if the this answer should be disabled
                            if (disablePlayerId > 0) { 
                                isDisabled = teamPlayer.id === disablePlayerId;
                            }

                            if (disableTeamId > 0) {
                                isDisabled = teamPlayer.teamId === disableTeamId;
                            }
                        }
                        else if (isTeam(a as Team)) {
                            answerTitle = `${(a as Team).name}`
                            photo = (a as Team).imagePath;
                            isDisabled = (a as Team).id === disableTeamId;
                            className = "team"
                        }
                        else if (isMultiChoiceAnswer(a as MultipleChoiceAnswer)) {
                            answerTitle = `${(a as MultipleChoiceAnswer).value}`
                        }

                        const elementClassNames = clsx(
                            "button",
                            "selectable-answer",
                            Number(props.currentValue?.response) === Number(a.id) ? "selected" : "not-selected",
                            photo ? "image" : "text",
                            subTitle ? "sub" : "",
                            isDisabled ? "disabled" : "",
                            className
                        )

                        return (
                            <div 
                                key={`selectable-answer-${a.id}`}
                                className={elementClassNames}
                                onClick={() => onAnswerSelected(a.id, isDisabled)}
                            >
                                {
                                    photo ? (
                                        <div className="photo">
                                            <img src={photo} alt={answerTitle} draggable={false} />
                                        </div>
                                    ) : null 
                                }
                                <h3 className="title">
                                    {answerTitle}
                                </h3>
                                {
                                    subTitle ? (
                                        <sub>
                                            {subTitle}
                                        </sub>
                                    ) : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        </>
    )

}

export default SelectableAnswer;
