import { ReactNode } from "react";
import clsx from "clsx";

import { ReactComponent as MenuIcon } from "../../svgs/kebab-menu.svg";

import "../../styles/scss/menu.scss";

type MenuProps = Readonly<{
    show: boolean
    title?: string
    className?: string,
    children: ReactNode
    onMenuButtonClicked: () => void
}>

const Menu = (props: MenuProps) => {
    return (
        <div className={clsx("menu", props.className)}>
            <div className="menu-title">
                {props.title}
                <MenuIcon className="icon" onClick={props.onMenuButtonClicked} />
            </div>
            <div className={clsx("menu-content", props.show ? "show" : "hide")}>
                { props.children }
            </div>
        </div>
    )
}

export default Menu;
