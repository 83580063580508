import { ReactNode, useState } from "react";
import clsx from "clsx";
import { apiCall } from "../../../functions";
import QuestionTypeEnum from "../../../enum/QuestionType";
import { TeamDivision } from "../../../enum/TeamDivision";
import AlertTypeEnum from "../../../enum/AlertTypeEnum";
import { ScreenSizeEnum } from "../../../enum/ScreenSizeEnum";
import { useAlert } from "../../../context/AlertContext";
import { useOrgData } from "../../../context/OrgContext";
import CheckboxField from "../../fields/CheckboxField";
import useScreenSize from "../../../hooks/useScreenSize";
import AwardNominee from "./AwardNominee";
import DivisionNomination from "./DivisionNominiation";
import AwardQuestion from "./AwardQuestion";
import AwardSection from "./AwardSection";
import TeamNomination from "./TeamNomination";
import Button from "../../commmon/Button";
import Modal from "../../commmon/Modal";
import Collapsible from "../../commmon/Collapsible";
import QuestionForm from "../forms/QuestionForm";
import AwardTextResponse from "./AwardTextResponse";
import AwardPointScale from "./AwardPointScale";
import AwardMultipleChoice from "./AwardMultipleChoice";

import "../../../styles/scss/pages/AwardPage.scss";

const AwardPage = () => {
    const { setAlertMessage } = useAlert();
    const { screenSize } = useScreenSize();
    const { season, awardData, reloadAwardData } = useOrgData();
    const [ showWinnersOnly, setShowWinnersOnly ] = useState<boolean>(false);
    const [ showQuestionForm, setShowQuestionForm ] = useState<boolean>(false);
    const [ activeQuestion, setActiveQuestion ] = useState<number>(0);
    const [ showNav, setShowNav ] = useState<boolean>(false);

    function onNomineeSelectedAsWinner(nominee: Nominee, question: AwardQuestion) {
        if (!season) {
            setAlertMessage("There was an error in updating the winner.", AlertTypeEnum.ERROR);
            console.error("Season id was not provided.");
        }

        // Prepare the payload
        const body: AwardPlayerWinner = {
            teamPlayersId: nominee.teamPlayer?.id || null,
            questionId: question.id,
            teamId: nominee.team.id,
            seasonId: season?.id!
        } as AwardPlayerWinner

        apiCall<AwardPlayerWinner>(`/award/winners/update`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        .then((resp) => {
            setAlertMessage(`Updated winner for '${question.text}'`, AlertTypeEnum.SUCCESS);
            reloadAwardData();
        })
        .catch((e) => {
            console.error(e);
            setAlertMessage(`An unexpected error occured while updating the winner.`, AlertTypeEnum.ERROR);
        })
    }

    function onSectionFormUpdate() {
        reloadAwardData();
    }

    function onQuestionFormClose() {
        setShowQuestionForm(false);
        reloadAwardData();
    }

    function onNavSelected(qId?: number) {
        if (qId) {
            setActiveQuestion(qId);
        }

        console.log("Testing", showNav, !showNav);
        setShowNav(!showNav);
    }

    return (
        <div id="award-page">
            <div className="content">
                <nav>
                    <Collapsible title="Questions" showNav={(screenSize ?? ScreenSizeEnum.MOBILE) > ScreenSizeEnum.SMALL ? true : showNav} onClick={onNavSelected}>
                        Winners only: <CheckboxField type="switch" onClick={(v) => setShowWinnersOnly(v)} name="winners-only" label="" checked={showWinnersOnly}/>
                        {
                            awardData?.awards.map((s) => {
                                const questions = s.questions.map((q) => {
                                    return (
                                        <a key={`s-${s.id}-q-${q.id}`}  href={`#question-${q.id}`}>

                                            <Button 
                                                className={clsx(activeQuestion === q.id ? "active" : "inactive")}
                                                onClick={() => onNavSelected(q.id)}
                                            >
                                                {q.text}
                                            </Button>
                                        </a>
                                    )
                                });
                                

                                return questions;
                            })
                        }
                        <Button onClick={() => { onNavSelected(); setShowQuestionForm(true) }}>Add Question</Button>
                        <Button onClick={() => {}}>Copy From Season</Button>
                    </Collapsible>
                </nav>
                <div className="wrapper">
                    <div className="details">
                        {
                            awardData?.awards.map((x) => (
                                <AwardSection 
                                    key={`section-${x.id}`} 
                                    section={x} 
                                    isActive={x.questions.map((q) => q.id).includes(activeQuestion)} 
                                    isEditable={true}
                                    onSectionUpdate={onSectionFormUpdate}
                                >
                                    {
                                        x.questions.map((q) => {
                                            const votes = awardData.votes.find((x) => x.questionId === q.id);
                                            const nomineeData: Array<Nominee> = votes.data;
                                            let nomineeComponent: Array<ReactNode> = [];

                                            switch (q.questionTypeId) {
                                                case QuestionTypeEnum.DIVISION:
                                                    const divisions: Array<TeamDivision> = nomineeData.reduce<Array<TeamDivision>>((prev, n) => {
                                                        if (!prev.includes(n.team.division)) {
                                                            prev.push(n.team.division);
                                                        }

                                                        return prev;
                                                    }, []);

                                                    nomineeComponent.push(divisions.map((d) => {
                                                        const nominees: Array<Nominee> = nomineeData
                                                            .filter((x: Nominee) => Number(x.team.division) === Number(d))
                                                            .filter((x: Nominee) => showWinnersOnly ? x.isWinner === showWinnersOnly : x);

                                                        return (
                                                            <DivisionNomination 
                                                                key={`q-${q.id}-division-${d}`} 
                                                                division={d}
                                                                team={nominees[0].team}
                                                            >
                                                                {
                                                                    nominees.map((n) => (
                                                                        <AwardNominee 
                                                                            key={`nominee-${n.teamPlayer.id}`} 
                                                                            nominee={n} 
                                                                            onNomineeSelected={(n: Nominee) => onNomineeSelectedAsWinner(n, q)}
                                                                        />
                                                                    ))
                                                                }
                                                            </DivisionNomination>
                                                        )
                                                    }))
                                                    break;
                                                case QuestionTypeEnum.TEAMPLAYERS:
                                                    if (showWinnersOnly) {
                                                        nomineeComponent.push(
                                                            <div className="winners">
                                                                {
                                                                    nomineeData.filter(x => x.isWinner).map((n) => (
                                                                        <AwardNominee 
                                                                            key={`nominee-${n.teamPlayer.id}`} 
                                                                            nominee={n} 
                                                                            onNomineeSelected={(n: Nominee) => onNomineeSelectedAsWinner(n, q)}
                                                                            showTeamName={true}
                                                                        />
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        const teams: Array<Team & VotingDetail> = nomineeData.reduce<Array<Team & VotingDetail>>((prev, n) => {
                                                            if (!prev.find((t) => t.id === n.team.id)) { 
                                                                prev.push(n.team);
                                                            }
    
                                                            return prev;
                                                        }, []);

                                                        nomineeComponent.push(
                                                            teams.map((t) => {
                                                                const nominees: Array<Nominee> = nomineeData
                                                                    .filter((x) => x.team.id === t.id)
                                                                    .filter((x) => showWinnersOnly ? x.isWinner === showWinnersOnly : x);
                                                                

                                                                return (
                                                                    <TeamNomination key={`team-${t.id}`} team={t}>
                                                                        {
                                                                            nominees.map((n) => (
                                                                                <AwardNominee 
                                                                                    key={`nominee-${n.teamPlayer.id}`} 
                                                                                    nominee={n} 
                                                                                    onNomineeSelected={(n: Nominee) => onNomineeSelectedAsWinner(n, q)}
                                                                                    showTeamName={false}
                                                                                />
                                                                            ))
                                                                        }
                                                                    </TeamNomination>
                                                                )
                                                            })
                                                        )
                                                    }
                                                    break;
                                                case QuestionTypeEnum.TEAMPHOTO:
                                                case QuestionTypeEnum.TEAM:
                                                    nomineeComponent = nomineeData
                                                        .filter((x) => showWinnersOnly ? x.isWinner === showWinnersOnly : x)
                                                        .map((n) => (
                                                            <AwardNominee 
                                                                key={`question-${q.id}-nominee-${n.team.id}`} 
                                                                nominee={n} 
                                                                onNomineeSelected={(n: Nominee) => onNomineeSelectedAsWinner(n, q)} 
                                                            />
                                                        ))
                                                    break;
                                                case QuestionTypeEnum.PLAYER:
                                                case QuestionTypeEnum.BESTTEAMPLAYER:
                                                case QuestionTypeEnum.ROOKIEONLY:
                                                    nomineeComponent = nomineeData
                                                        .filter((x) => showWinnersOnly ? x.isWinner === showWinnersOnly : x)
                                                        .map((n) => (
                                                            <AwardNominee 
                                                                key={`question-${q.id}-nominee-${n.teamPlayer.id}`} 
                                                                nominee={n} 
                                                                onNomineeSelected={(n: Nominee) => onNomineeSelectedAsWinner(n, q)} 
                                                            />
                                                        ))
                                                    break;
                                                case QuestionTypeEnum.MULTIPLECHOICE:
                                                    nomineeComponent.push(
                                                        <AwardMultipleChoice key={`multi-choice-${q.id}`} responses={nomineeData} />
                                                    );
                                                    break;
                                                case QuestionTypeEnum.POINTSCALE:
                                                    nomineeComponent.push(
                                                        <AwardPointScale key={`point-scale-${q.id}`} responses={nomineeData} />
                                                    );
                                                    break;
                                                case QuestionTypeEnum.TEXT:
                                                    nomineeComponent.push(
                                                        <AwardTextResponse key={`text-response-${q.id}`} responses={nomineeData} />
                                                    );
                                                    break;
                                                case QuestionTypeEnum.SUBMISSION:
                                                case QuestionTypeEnum.MESSAGE:
                                                    break;
                                                default: 
                                                    return (<div key={`q-${q.id}-unknown`}>UNKNOWN</div>);
                                            }

                                            return (
                                                <AwardQuestion 
                                                    key={`award-question-${q.id}`} 
                                                    question={q} 
                                                    votePercent={votes.votePercent} 
                                                    isActive={activeQuestion === q.id}
                                                    onQuestionUpdate={onQuestionFormClose}
                                                >
                                                    { nomineeComponent }
                                                </AwardQuestion>
                                            )
                                        })
                                    }
                                </AwardSection>
                            ))
                        }
                    </div>
                </div>
            </div>
            {
                showQuestionForm && (
                    <Modal onModalClose={() => setShowQuestionForm(false)}>
                        <QuestionForm question={{} as AwardQuestion} onFormSuccess={onQuestionFormClose} onFormCancel={() => setShowQuestionForm(false)} />
                    </Modal>
                )
            }
        </div>
    )
}

export default AwardPage;
