import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../functions";
import Card from "../commmon/Card";
import Button from "../commmon/Button";
import SelectField from "../fields/SelectField";
import InputField from "../fields/InputField";
import { useOrgData } from "../../context/OrgContext";

import { ReactComponent as HomeIcon } from "../../svgs/home.svg";

import "../../styles/scss/pages/PlayerDetailsPage.scss";
import useDebounce from "../../hooks/useDebounce";

const PlayerDetailsPage = () => {
    const nav = useNavigate();
    const debounce = useDebounce(updateFilteredPlayers, 1000);
    const { org } = useOrgData();
    const [players, setPlayers] = useState<Player[]>([]);
    const [searchField, setSearchField] = useState<string>("");
    const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = useState<number>(25);

    useEffect(() => {
        apiCall<Player[]>(`/players/search?org=${org?.id}`)
        .then((resp) => {
            setPlayers(resp.data)
        })
        .catch((err) => console.error(err));
    }, []);

    useEffect(() => {
        setTotalPages(Math.ceil(players.length / itemsPerPage));
        setFilteredPlayers(players.slice(0, itemsPerPage));
        setPage(0);
    }, [players]);
    
    useEffect(() => {
        const start: number = page * itemsPerPage;
        setFilteredPlayers(players.slice(start, start + itemsPerPage));
    }, [page, itemsPerPage])

    function onFilterUpdate(e: ChangeEvent<HTMLInputElement>) {
        const value = e.currentTarget.value;

        setSearchField(value);
        debounce(value);
    }

    function updateFilteredPlayers() {
        if (searchField) {
            const filterPlayers = players.filter((p) => {
                if (p.aka && p.aka.includes(searchField)) {
                    return true;
                }

                return `${p.firstName} ${p.lastName}`.toLowerCase().includes(searchField.toLowerCase()) || p.email.toLowerCase().includes(searchField.toLowerCase())
            });

            setSearchField(() => searchField);
            setFilteredPlayers(() => filterPlayers)
        }
        else {
            setSearchField(() => "")
            setFilteredPlayers(() => players);
        }

        setPage(() => 0);
        setTotalPages(() => Math.floor(filteredPlayers.length / itemsPerPage))
    }

    function onPageChange(num: number) {
        if (num < 0) {
            setPage(0);
        }
        else if (num >= totalPages) {
            setPage(totalPages);
        }
        else {
            setPage(num);
        }
    }

    function onPageNext() {
        onPageChange(page + 1)
    }
    
    function onPageLast() {
        onPageChange(page - 1)
    }

    function onItemsPerPageChanged(e: ChangeEvent<HTMLSelectElement>) {
        const value: number = Number(e.currentTarget.value);
        setItemsPerPage(value);
        setTotalPages(Math.floor(players.length / value));
    }

    return (
        <div id="page--player-detail">
            <div>
                <HomeIcon className="icon" onClick={() => nav(`/org/${org?.id}`)} /> / Players In Organization
            </div>
            <div>
                <InputField name="player-search" placeholder="Search" onChange={onFilterUpdate} value={searchField} />
                <ul>
                    {
                        filteredPlayers.map((p) => (
                            <li key={p.id}>
                                <Card onClick={() => nav(`/org/${org?.id}/players/${p.id}`, { state: { player: p } } )}>
                                    <Card.Header>
                                        {p.firstName} {p.aka ? `"${p.aka}"` : ``} {p.lastName}
                                    </Card.Header>
                                </Card>
                            </li>
                        ))
                    }
                </ul>
                <div className="pages">
                    <div className="nav">
                        <Button onClick={onPageLast}>&lt;-</Button>
                        <p>Page {page + 1} of {totalPages + 1} </p>
                        <Button onClick={onPageNext}>-&gt;</Button>
                    </div>
                    Per Page: 
                    <SelectField name="items" label="" value={itemsPerPage} onChange={onItemsPerPageChanged}>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                    </SelectField>
                </div>
            </div>
        </div>
    )
}

export default PlayerDetailsPage;
