
import clsx from "clsx";
import { ReactComponent as StarIcon} from "../../../svgs/star-empty.svg";
import { ReactComponent as StarFilledIcon} from "../../../svgs/star-full.svg";
import { getPlayerName } from "../../../functions";

type AwardNomineeProps = Readonly<{
    nominee: Nominee
    showTeamName?: boolean
    onNomineeSelected: (n: Nominee) => void
}>

const AwardNominee = (props: AwardNomineeProps) => {
    const showTeamName: boolean = props.showTeamName ?? true;

    return (
        <div className={clsx("nominee", props.nominee.isWinner ? "winner" : "no-win")}>
            <aside>{ props.nominee.rank })</aside>
            <header>
                { props.nominee.player ? getPlayerName(props.nominee.player) : props.nominee.team.name }
            </header>
            <div className="votes">
                { props.nominee.votes }
            </div>
            <div className={clsx("winner-icon", props.nominee.isWinner ? "winner" : "no-win")}>
                {
                    props.nominee.isWinner ? (
                        <StarFilledIcon className="icon" onClick={() => props.onNomineeSelected(props.nominee)} />
                    ) : (
                        <StarIcon className="icon" onClick={() => props.onNomineeSelected(props.nominee)} />
                    )
                }
                
            </div>
            <sub>
                {
                    showTeamName && (
                        <div>{ props.nominee.team.name }</div>
                    )
                }
            </sub>
            
        </div>
    )
}

export default AwardNominee;
