import { ButtonHTMLAttributes, ReactNode } from "react";

import "../../styles/scss/button.scss";

type ButtonProps = Readonly<{
    icon?: ReactNode
    variant?: "primary" | "secondary"
    children: ReactNode
}> & ButtonHTMLAttributes<HTMLButtonElement>

const Button = (props: ButtonProps) => {
    return (
        <button {...props}>
            { props.icon }
            { props.children }
        </button>
    )
}

export default Button;
