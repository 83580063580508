import { FormEvent, ReactNode, useState } from "react";
import FormRow from "./FormRow";
import Button from "./Button";

import "../../styles/scss/form.scss";
import "../../styles/scss/loading-spinner.scss";

export interface IFormCallback {
    onFormSuccess: () => void
    onFormError?: () => void
    onFormCancel?: () => void
    submitButtonText?: string
    enableSubmissions?: boolean
}

type FormProps = Readonly<{
    children?: ReactNode
    showDelete?: boolean
    hideCancel?: boolean
    enableSubmissions?: boolean
    submitButtonText?: string
    onCancelClicked?: () => void
    onDeleteClicked?: () => Promise<boolean>
    onSubmitClicked: (e: FormEvent<HTMLFormElement>, formData: GenericObject) => Promise<boolean>
}>

const Form = (props: FormProps) => {
    const hideCancel = props.hideCancel ? true : false
    const enableSubmissions = props.enableSubmissions ?? true
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();

        const formData = parseFormData(e);

        if (props.onSubmitClicked) {
            setIsSubmitting(true);
            props.onSubmitClicked(e, formData)
            .finally(() => {
                setIsSubmitting(false);
            });
        }
    }

    function onDeleteClicked() {
        if (props.onDeleteClicked) {
            setIsSubmitting(true);
            props.onDeleteClicked()
            .finally(() => {
                setIsSubmitting(false);
            });
        }
    }

    function parseFormData(e: FormEvent<HTMLFormElement>): GenericObject {
        const data: GenericObject = { }
    
        for(let i = 0; i < e.currentTarget.length; i++) {
            const nodeName = e.currentTarget[i].nodeName;
            const elementType = e.currentTarget[i].getAttribute("type");
            const elementName = e.currentTarget[i].getAttribute("name");
            let elementValue = undefined;
    
            if (nodeName.toLowerCase() === 'input') {
                switch (elementType) {
                    case 'email':
                    case 'text':
                    case 'hidden':
                        elementValue = (e.currentTarget[i] as HTMLFormElement)?.value.trim();
                        break;
                    case 'number':
                        elementValue = Number((e.currentTarget[i] as HTMLFormElement)?.value);
                        break;
                    case 'checkbox':
                        elementValue = (e.currentTarget[i] as HTMLFormElement)?.checked;
                        break;
                    case 'password': 
                        elementValue = (e.currentTarget[i] as HTMLFormElement)?.value
                        break;
                    case 'file':
                        elementValue = (e.currentTarget[i] as HTMLFormElement)?.files[0];
                        break;
                    default: 
                        console.debug(`Unknown type: ${elementType}`)
                }
            }
            else if (nodeName.toLowerCase() === 'select') {
                if (e.currentTarget[i].getAttribute("data-type") === "number") {
                    elementValue = Number((e.currentTarget[i] as HTMLSelectElement)?.value);
                }
                else {
                    elementValue = (e.currentTarget[i] as HTMLSelectElement)?.value.trim();
                }
            }
            else if (nodeName.toLowerCase() === 'textarea') {
                elementValue = (e.currentTarget[i] as HTMLFormElement)?.value.trim();
            }
    
            if (elementName) {
                if (elementName.includes("[]")) {
                    if (!data[elementName.replace("[]", "")]) { 
                        data[elementName.replace("[]", "")] = [];
                    }
    
                    data[elementName.replace("[]", "")].push(elementValue);
                }
                else {
                    data[elementName] = elementValue;
                }
            }
        }
    
        return data;
    }

    return (
        <form onSubmit={onSubmit}>
            {props.children}
            <FormRow className="buttons">
                {
                    props.showDelete && (
                        <Button 
                            disabled={isSubmitting || !enableSubmissions}
                            role="button" 
                            type="button" 
                            className="danger" 
                            onClick={onDeleteClicked}
                        >
                                Delete
                        </Button>
                    )
                }
                {
                    hideCancel ? (
                        <></>
                    ) : (
                        <Button 
                            disabled={isSubmitting}
                            role="button" 
                            type="button" 
                            className="selected"
                            onClick={props.onCancelClicked}
                        >
                            Cancel
                        </Button>
                    )
                }
                <Button 
                    disabled={isSubmitting || !enableSubmissions}
                    role="button" 
                    type="submit"
                    className="selected"
                >
                    {
                        isSubmitting ? (
                            <div className="loading-spinner" />
                        ) : (
                            props.submitButtonText ? props.submitButtonText : "Submit"
                        )
                    }
                </Button>
            </FormRow>
        </form>
    )
}

export default Form;