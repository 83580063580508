//@ts-ignore
import CanvasJSReact from "@canvasjs/react-charts";

type AwardMultipleChoiceProps = Readonly<{
    responses: Array<Nominee>
}>

const AwardMultipleChoice = (props: AwardMultipleChoiceProps) => {
    const CanvasJS = CanvasJSReact.CanvasJS;
    const CanvasChart = CanvasJSReact.CanvasJSChart;
    const pieOptions = {
        animationEnabled: true,
        data: [{
            type: "pie",
            showInLegend: true,
            legendText: "{label}",
            toolTipContent: "{label}: \n<strong>{y}</strong>",
            indexLabel: "{y}",
            indexLabelPlacement: "inside",
            explodeOnClick: true,
            dataPoints: props.responses.map((x) => ({ y: x.votes, label: x.textResponse }))
        }]
    }

    return (
        <div className="multiple-choice-responses">
            {
                <CanvasChart options={pieOptions} />
            }
        </div>
    )
}

export default AwardMultipleChoice;
