import { useState } from "react";
import AlertTypeEnum from "../../enum/AlertTypeEnum";
import { apiRequest } from "../../functions";
import { useAlert } from "../../context/AlertContext";
import CheckboxField from "../fields/CheckboxField";
import PlayerForm from "./forms/PlayerForm";
import Modal from "../commmon/Modal";

import {ReactComponent as DeleteIcon} from "../../svgs/bin.svg";
import {ReactComponent as EditIcon} from "../../svgs/pencil.svg";
import "../../styles/scss/admin-team-player.scss";

type SeasonPlayerProps = Readonly<{
    teamPlayer: TeamPlayers
    teamId: number
    onRefreshTeam: () => void
}>

const SeasonPlayer = function(props: SeasonPlayerProps) {
    const [editPlayer, setEditPlayer] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isCaptain, setIsCaptain] = useState<boolean>(props.teamPlayer.captian);
    const { setAlertMessage } = useAlert();

    function onCaptainToggled(val: boolean) {
        setIsUpdating(true);
        apiRequest<TeamPlayers>(`/teamplayers/save`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                teamPlayer: {
                    id: props.teamPlayer.id,
                    playerId: props.teamPlayer.playerId,
                    seasonId: props.teamPlayer.seasonId,
                    teamId: props.teamId,
                    captian: val,
                } as Partial<TeamPlayers>
            })
        })
        .then((resp) => {
            if (resp.success) {
                setAlertMessage(`The player has been updated.`, AlertTypeEnum.SUCCESS);
                setIsCaptain(val);
            }
            else {
                setAlertMessage(resp.message, AlertTypeEnum.ERROR);
            }
        })
        .catch((err) => {
            setAlertMessage(`An unexpected error has occured.`, AlertTypeEnum.ERROR);
            console.error(err);
        })
        .finally(() => {
            setIsUpdating(false);
        })
    }

    function onRemovePlayerFromTeam() {
        apiRequest(`/teamplayers/remove`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                playerId: props.teamPlayer.playerId,
                seasonId: props.teamPlayer.seasonId,
                teamId: props.teamId,
            })
        })
        .then((resp) => {
            if (resp.success) {
                setAlertMessage(`The player has been removed from the team.`, AlertTypeEnum.SUCCESS);
                props.onRefreshTeam();
            }
            else {
                setAlertMessage(resp.message, AlertTypeEnum.ERROR);
            }
        })
        .catch((err) => {
            setAlertMessage(`An unexpected error has occured.`, AlertTypeEnum.ERROR);
            console.error(err);
        })
    }

    return (
        <div className="admin-team-player">
            <div className="player-data">
                <div className="player-name">
                    {props.teamPlayer.player?.firstName} {props.teamPlayer.player?.lastName} 
                    <EditIcon className="icon" onClick={() => setEditPlayer(true)} />
                    <DeleteIcon className="icon" onClick={onRemovePlayerFromTeam} />
                </div>
                <div className="captain-toggle">
                    <CheckboxField 
                        type="switch" 
                        checked={isCaptain} 
                        label="" 
                        name="captain" 
                        onClick={onCaptainToggled}
                        disabled={isUpdating}
                    />
                </div>
            </div>
            {
                editPlayer && (
                    <Modal onModalClose={() => setEditPlayer(false)}>
                        <PlayerForm 
                            player={props.teamPlayer.player} 
                            onFormCancelled={() => setEditPlayer(false)}
                            onFormSuccess={() => { setEditPlayer(false); props.onRefreshTeam(); }}
                        />
                    </Modal>
                )
            }
        </div>
    )
}

export default SeasonPlayer;