import { ChangeEvent, createRef, useState } from "react";
import { v4 as uuid } from "uuid";

import "../../styles/scss/fields/ImageField.scss";

type ImageFieldProps = Readonly<{
    name: string
    src: string
    overlayText?: string
    onImageChanged?: (e: ChangeEvent<HTMLInputElement>) => void
}>

const ImageField = (props: ImageFieldProps) => {
    const imageRef = createRef<HTMLInputElement>();
    const [photo, setPhoto] = useState<string>(props.src ?? "");
    const id = uuid();

    function onPhotoChanged(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files?.length > 0) {
            setPhoto(() => e.target.files ? URL.createObjectURL(e.target.files[0]) : "");
        }

        if (props.onImageChanged) {
            props.onImageChanged(e);
        }
    }

    return (
        <div className="image-field">
            <div className="file-input">
                <input id={id} ref={imageRef} type="file" onChange={onPhotoChanged} name={props.name} aria-label={props.name} />
            </div>
            <label htmlFor={props.overlayText ? id : ""}>
                <img 
                    src={photo}
                    alt={props.name}
                />
                {
                    props.overlayText && (
                        <div className="overlay">
                            <p>{props.overlayText}</p>
                        </div>
                    )
                }
            </label>
        </div>
    )
}

export default ImageField;
