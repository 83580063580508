import { ReactNode } from "react"
import { TeamDivision } from "../../../enum/TeamDivision"
import Card from "../../commmon/Card"
import ProgressBar from "../../commmon/ProgressBar"

type DivisionNominationProps = Readonly<{
    division: TeamDivision
    team: Team & VotingDetail
    children: ReactNode
}>

const DivisionNomination = (props: DivisionNominationProps) => {
    return (
        <div className="division-nomination">
            <Card className="display-only">
                <Card.Header>
                    <ProgressBar legend={TeamDivision[props.division ?? TeamDivision.NONE].toString()} percent={props.team.totalPercent} />
                </Card.Header>
                <Card.Content>
                    { props.children }
                </Card.Content>
            </Card>
        </div>
    )
}

export default DivisionNomination;
