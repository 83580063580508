import { ReactNode } from "react"
import Card from "../../commmon/Card"
import ProgressBar from "../../commmon/ProgressBar"

type TeamNominationProps = Readonly<{
    team: Team & VotingDetail
    children: ReactNode
}>

const TeamNomination = (props: TeamNominationProps) => {
    return (
        <div className="team-nomination">
            <Card className="display-only">
                <Card.Header>
                    <ProgressBar legend={props.team.name ?? ""} percent={props.team.totalPercent} />
                </Card.Header>
                <Card.Content>
                    { props.children }
                </Card.Content>
            </Card>
        </div>
    )
}

export default TeamNomination;
