import clsx from "clsx";
import { ReactNode, useState } from "react";

import {ReactComponent as EditIcon} from "../../../svgs/pencil.svg";
import SectionForm from "../forms/SectionForm";
import Modal from "../../commmon/Modal";

type AwardSectionProps = Readonly<{
    isActive: boolean
    isEditable?: boolean
    section: AwardSection
    children?: ReactNode
    onSectionUpdate?: () => void
}>

const AwardSection = (props: AwardSectionProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);

    function onEditSection() {
        setIsEditing(true);
    }

    function onCloseForm() {
        setIsEditing(false);
    }

    function onFormSuccess() {
        setIsEditing(false);

        if (props.onSectionUpdate) {
            props.onSectionUpdate();
        }
    }

    return (
        <div className={clsx("award-section", props.isActive ? "active" : "inactive")}>
            <header>
                { props.section.title }
                { props.isEditable && <EditIcon className="icon" onClick={onEditSection}/> }
            </header>
            <div className="section-content">
                { props.children }
            </div>
            {
                isEditing && (
                    <Modal onModalClose={onCloseForm}>
                        <SectionForm section={props.section} onFormSuccess={onFormSuccess} onFormCancel={onCloseForm} />
                    </Modal>
                )
            }
        </div>
    )
}

export default AwardSection;
