import { createRef, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
import { CSVDownload } from "react-csv";
import QRCode from "react-qr-code";
import { useOrgData } from "../../context/OrgContext";
import { useAlert } from "../../context/AlertContext";
import Menu from "../commmon/Menu";
import Button from "../commmon/Button";
import Loading from "../commmon/Loading";
import AlertTypeEnum from "../../enum/AlertTypeEnum";
import SeasonForm from "./forms/SeasonForm";
import LeagueAppsModal from "./modals/LeagueAppsModal";
import LeagueLabModal from "./modals/LeagueLabModal";
import AwardPage from "./awards/AwardPage";
import TeamPage from "./awards/TeamPage";

import { ReactComponent as LeagueAppsIcon } from "../../svgs/vendors/LeagueAppsLogo.svg";
import { ReactComponent as LeagueLabIcon } from "../../svgs/vendors/LeagueLabLogo.svg";
import { ReactComponent as HomeIcon } from "../../svgs/home.svg";

import "../../styles/scss/admin-season.scss";
import "../../styles/scss/tab-group.scss";

enum SeasonTabs {
    TEAMS,
    AWARDS,
    EDIT
    // REFEREES
}

const AwardSeason = function() {
    const nav = useNavigate();
    const qrCodeRef = createRef<QRCode & SVGSVGElement>();
    const { setAlertMessage } = useAlert();
    const { seasonId, orgId } = useParams();
    const { season, integrations, loadData, reloadSeasonData } = useOrgData();
    const printAwardsRef = useRef<HTMLAnchorElement | null>(null);
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<number>(SeasonTabs.TEAMS);
    const [csvData, setCSVData] = useState<string>();
    const [showLeagueApps, setShowLeagueApps] = useState<boolean>(false);
    const [showLeagueLab, setShowLeagueLab] = useState<boolean>(false);
    const [showPrintAwardsModal, setShowPrintAwardsModal] = useState<boolean>(false);
    const [ showMenu, setShowMenu ] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
    }, [])
    
    useEffect(() => {
        loadData(orgId, seasonId)
        .finally(() => {
            setIsLoading(false);

            if (seasonId === "new") {
                setCurrentTab(SeasonTabs.EDIT);
            }
        });
    }, [seasonId, orgId]);

    function onDownloadIconClicked() {
        fetch(`/api/awards/download/${season?.id}`)
        .then(body => body.text())
        .then((data) => {
            if (data) {
                setCSVData(data)
                setTimeout(() => {
                    setCSVData(undefined);
                }, 5000)
            }
            else {
                console.error("")
            }
        });
    }

    function onRefreshClicked() {
        setIsLoading(true);
        setAlertMessage(`Refreshing season data...`, AlertTypeEnum.INFO);
        // loadNonTeamPlayers();
        reloadSeasonData()
        .then((success) => {
            if (success) {
                setAlertMessage("Season information was refreshed", AlertTypeEnum.SUCCESS);
            }
            else {
                setAlertMessage("An error occured when refreshing the season", AlertTypeEnum.ERROR);
            }
        })
        .catch(() => {
            setAlertMessage("An error occured when refreshing the season", AlertTypeEnum.ERROR);
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    function onFormCancel() {
        if (season) {
            setCurrentTab(SeasonTabs.AWARDS);
        }
        else {
            nav(`../seasons`);
        }
    }

    function onFormSuccess(season: Season | null) {
        if (season) {
            if (seasonId === "new") {
                // Redirect to the new season
                nav(`../seasons/${season.id}`, { replace: true });
            }

            loadData(orgId, seasonId);
        }
        else if (season === null) {
            nav(`../seasons/`);
        }

        setCurrentTab(SeasonTabs.AWARDS);
    }

    function onCloseIntegrationModal() {
        setShowLeagueApps(false)
        setShowLeagueLab(false);
        setIsEditing(false);
        onRefreshClicked();
    }

    function onQRCodeDownloadClicked() {
        const svg = qrCodeRef.current;
        const link = document.createElement("a");

        if (svg && link) {
            const svgData = (new XMLSerializer()).serializeToString(svg);
            const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgData);
            
            link.setAttribute("href", url);
            link.setAttribute("download", "VoxVerdict_QRCode.svg");
            link.click();
        }
    }
    
    function onPrintAwardsClick() {
        fetch(`/api/awards/print?seasonId=${season?.id}`, {
            method: "GET"
        })
        .then((resp) => resp.text())
        .then((pdfData) => {
            const aRef = printAwardsRef.current;
            if (aRef) {
                aRef.download = `${season?.name}.pdf`;
                aRef.href = `data:application/pdf;base64,${pdfData}`;
                aRef.click();
            }
        })
        .catch((err) => {
            setAlertMessage((err as Error).message, AlertTypeEnum.ERROR);
        });
    }

    function onHomeIconClicked() {
        nav(`../seasons`);
    }
    
    return (
        <div className="admin-season">
            <header id="season-header">
                <h3>
                    <HomeIcon className="icon" onClick={onHomeIconClicked} />
                    { season?.name }
                </h3>
                {
                    !isEditing && (
                        <div>
                            <h3>
                                <Menu 
                                    show={showMenu} 
                                    title={`${ season?.seasonType.name } ${ season?.sport.name } ${ season?.year }`} 
                                    onMenuButtonClicked={() => setShowMenu(!showMenu)}
                                >
                                    <Button onClick={() => { onRefreshClicked(); setShowMenu(false); }}>Refresh Data</Button>
                                    <Button onClick={() => { setCurrentTab(SeasonTabs.TEAMS); setShowMenu(false); }} className={clsx(currentTab === SeasonTabs.TEAMS) ? "active" : ""}>Edit Teams / Players</Button>
                                    <Button onClick={() => { setCurrentTab(SeasonTabs.AWARDS); setShowMenu(false); }} className={clsx(currentTab === SeasonTabs.AWARDS) ? "active" : ""}>View Awards</Button>
                                    <Button onClick={() => { setCurrentTab(SeasonTabs.EDIT); setShowMenu(false); }} className={clsx(currentTab === SeasonTabs.EDIT) ? "active" : ""}>Edit Season</Button>
                                    <Button onClick={() => { onPrintAwardsClick(); setShowMenu(false); }}>Print Awards</Button>
                                    <Button onClick={() => { onDownloadIconClicked(); setShowMenu(false); }}>Download CSV</Button>
                                    <Button onClick={() => { onQRCodeDownloadClicked(); setShowMenu(false); }}>Download QR Code</Button>
                                    <div className="integrations">
                                        {
                                            integrations.find(x => x.name === "leagueapps") && (
                                                <Button onClick={() => { setShowMenu(false); setShowLeagueApps(true); }}>
                                                    <LeagueAppsIcon className="icon leagueapps" /> League Apps
                                                </Button>
                                            )
                                        }
                                        {
                                            integrations.find(x => x.name === "leaguelab") && (
                                                <Button onClick={() => { setShowMenu(false); setShowLeagueLab(true); }}>
                                                    <LeagueLabIcon className="icon leaguelab" /> League Lab
                                                </Button>
                                            )
                                        }
                                    </div>
                                </Menu>
                            </h3>
                        </div>
                    )
                }
            </header>
            {
                currentTab === SeasonTabs.AWARDS ? (
                    <AwardPage />
                ) : null
            }
            {
                currentTab === SeasonTabs.TEAMS ? (
                    <TeamPage seasonId={season?.id ?? Number(seasonId)}/>
                ) : null
            }
            {
                currentTab === SeasonTabs.EDIT ? (
                    <SeasonForm 
                        onFormCancel={onFormCancel}
                        onFormSuccess={onFormSuccess}
                        season={season}
                    />
                ) : null
            }
            {
                csvData ? (
                    <CSVDownload data={csvData} target="_blank" />
                ) : null
            }
            {
                showLeagueApps ? (
                    <LeagueAppsModal onClose={onCloseIntegrationModal}/>
                ) : null
            }
            {
                showLeagueLab ? (
                    <LeagueLabModal onClose={onCloseIntegrationModal}/>
                ) : null
            }
            <QRCode 
                ref={qrCodeRef}
                className="hidden"
                size={512}
                value={`https://voxverdict.com`}
            />
            <a className="hide" ref={printAwardsRef} />
            {
                isLoading && (
                    <Loading />
                )
            }
        </div>
    )
}

export default AwardSeason;
