import { CSSProperties, useState } from "react";
import Button from "../../commmon/Button";

import "../../../styles/scss/responses/TextResponse.scss";

type AwardTextResponseProps = Readonly<{
    responses: Array<Nominee>
}>

const AwardTextResponse = (props: AwardTextResponseProps) => {
    const [ view, setView ] = useState<number>(0);

    function onPrevResponse() {
        if (view === 0) {
            setView(0);
        }
        else {
            setView(view - 1);
        }
    }

    function onNextResponse() {
        if (view === (props.responses.length - 1)) {
            setView(props.responses.length - 1);
        }
        else {
            setView(view + 1);
        }
    }

    return (
        <div className="text-feedback">
            {
                props.responses.length > 0 ? (
                    <>
                        <div className="content">
                            <div className="responses" style={{"--viewing": view} as CSSProperties}>
                                {
                                    props.responses.map((r, i) => (
                                        <div key={`response-${i}`} className="feedback">
                                            <div>{r.textResponse}</div>
                                            <div className="author">
                                                <div className="author-name">
                                                    - {r.player?.firstName} {r.player?.lastName}
                                                </div>
                                                <div>
                                                    {r.team.name}
                                                </div>
                                                <div className="date">
                                                    {/* {(new Date(r.)).toLocaleString("en-us", { month: "long", day: "numeric", year: "numeric" })} */}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="controls-wrapper">
                            <div className="controls">
                                <Button className="selected" onClick={onPrevResponse}>Last</Button>
                                <div>{view + 1} of {props.responses.length}</div>
                                <Button className="selected" onClick={onNextResponse}>Next</Button>
                            </div>
                        </div>
                    </>
                ) : (
                    "There are no responses to display"
                )
            }
        </div>
    )
}

export default AwardTextResponse;
